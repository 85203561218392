import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "benjamin-shop"
    }}>{`Benjamin Shop`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "fishing-rod"
    }}>{`Fishing Rod`}</h3>
    <p>{`You can use these fishing rods at the pool in your farm. There are 2 types of rod.`}</p>
    <ul>
      <li parentName="ul">{`Old Rod`}</li>
      <li parentName="ul">{`Steel Rod`}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/fish-1.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      